import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import DelegateForm from "../components/MissDelegateForm";

// Styled Components
const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-md);
`;

const List = styled.ul`
  list-style: none;
  padding-left: var(--spacing-lg);
  margin-bottom: var(--spacing-md);
`;

const ListItem = styled.li`
  font-size: 1.2rem;
  margin-bottom: var(--spacing-sm);
`;

const Link = styled.a`
  color: var(--color-yellow);
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: var(--color-dark-gray);
  }
`;

const BecomeAMissContestant = () => {
  return (
    <Layout pageTitle="Become a Miss Buffalo Contestant">
      <SectionTitle>Eligibility for the 2024 Season</SectionTitle>
      <Paragraph>
        If all of the following are true, then the candidate is eligible to
        compete in a Miss New York local competition:
      </Paragraph>
      <List>
        <ListItem>
          Must be no less than 18 years old by December 31, 2024
        </ListItem>
        <ListItem>
          Must be no more than 28 years old on December 31, 2024
        </ListItem>
      </List>

      <List>
        <ListItem>
          Home Address – Must be a legal resident in a qualifying county (varies
          by local competition) for no less than 30 consecutive days prior to
          the local competition.
        </ListItem>
        <ListItem>
          Student Status – Registered for classes with full-time status at an
          accredited institution in a qualifying county (varies by local
          competition) prior to the local competition. Classes must be attended
          in person*
        </ListItem>
        <ListItem>
          Employment Status – Must be a full time employee for no less than 60
          consecutive days in a qualifying county (varies by local competition)
          prior to the local competition.
        </ListItem>
      </List>

      <List>
        <ListItem>Must identify as female</ListItem>
        <ListItem>
          Cannot be pregnant and will not become pregnant during candidate’s
          year of service
        </ListItem>
        <ListItem>
          Cannot be a parent or legal guardian and will not become a parent or
          legal guardian during candidate’s year of service
        </ListItem>
        <ListItem>Cannot be or have ever been married</ListItem>
        <ListItem>
          Cannot have been convicted of any crime greater than a misdemeanor.
        </ListItem>
      </List>

      <SectionTitle>Job Description</SectionTitle>
      <Paragraph>
        Miss Buffalo & Miss Niagara are the public faces of the Miss Buffalo &
        Miss Niagara Scholarship Program. They are the local representatives of
        the Miss New York and Miss America Organizations.
      </Paragraph>
      <Paragraph>
        We expect our titleholders will take an active role in our community and
        be role models to others. They will maintain a clean and active presence
        in person and on social media and conduct themselves with the integrity
        worthy of the iconic Miss America crown.
      </Paragraph>
      <Paragraph>
        Our titleholders will have opportunities to earn scholarships, develop
        public speaking skills, engage in community activism, and network with
        professionals from around the state. They will both go on to compete at
        the Miss New York Competition.
      </Paragraph>

      <SectionTitle>Community Service Initiative</SectionTitle>
      <Paragraph>
        Candidates are required to choose a Community Service Initiative (CSI)
        that they will support, advocate for, and promote during their year as
        titleholders. A candidate should select her SII based on her passion or
        beliefs. She can create her SII around an organization, an idea, or a
        movement. Some examples include “Financial Literacy in Youth,” “The Boys
        and Girls Club of America,” “Breast Cancer Awareness,” “Putting an End
        to Bullying,” and “Achieving Success Through Positive Body Image.”
      </Paragraph>

      <SectionTitle>Financial Commitment</SectionTitle>
      <Paragraph>
        The Miss America Organization now requires a modest entry fee to
        participate in the program. Your entry fees go directly to the
        scholarships awarded at the national and local level.
      </Paragraph>
      <Paragraph>
        To compete in any Miss America local competition, you must register with
        the Miss America Organization at this website and pay a fee of $35.
      </Paragraph>
      <Paragraph>
        This fee allows you to compete in local competitions and makes you
        eligible for additional scholarships. It also gives you exclusive access
        to the Miss America Professional Development Series Webinars and other
        content.
      </Paragraph>
      <Paragraph>
        This fee goes into the{" "}
        <Link href="https://members.missamerica.org/register">
          Miss America Foundation Scholarship Fund
        </Link>
        .
      </Paragraph>
      <Paragraph>
        Once you’re registered with Miss America, you have to register for each
        local you wish to compete at the Miss Buffalo & Niagara Competition on
        the Miss America page at this link. There is a $150 fee to register.
        This fee goes to our local scholarship fund.
      </Paragraph>
      <DelegateForm />
    </Layout>
  );
};

export default BecomeAMissContestant;
