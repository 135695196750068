import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

// Styled Components
const LegacyList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: var(--spacing-lg) 0;
`;

const LegacyItem = styled.li`
  font-size: var(--font-size-base);
  margin-bottom: var(--spacing-xs);
  line-height: var(--line-height-base);

  & > strong {
    font-weight: bold;
  }
`;

const Legacy = () => {
  const titleholders = [
    "1972: Judy Jane Jack (2nd Runner Up)",
    "1973: Bonnie Ann Wingerter (3rd Runner Up)",
    "1974: Susan Makai",
    "1975: Charlene Lauria",
    "1976: Terrie Ann George",
    "1979: Suzanne Lynn Bannister",
    "1980: Denise Margaret Daniels",
    "1981: Debra Parker",
    "1982: Patricia Latin",
    "1983: Carol McCaig",
    "1984: Patricia Latin (Finalist)",
    "1985: Jill Privatera (Miss New York)",
    "1986: Cynthia Ratajczak (2nd Runner Up)",
    "1987: Kathleen Marie Kelly",
    "1988: Gail Decker",
    "1989: Elizabeth DiStasio",
    "1990: Maryalice Demler (Miss New York)",
    "1991: Deanna Lee Demmin",
    "1992: Angela Kim",
    "1993: Jennifer Dittmar",
    "1994: Lisa Burczynski",
    "1995: Elaine Dalbo",
    "1996: Kimberly Piazza",
    "1997: Detra McTier",
    "1998: Kimberly Piazza (2nd Runner Up)",
    "1999: Rebecca Lesniak",
    "2000: Krista Robusto",
    "2001: Rebecca Lesniak",
    "2002: Jennifer Farnham",
    "2003: Maia Evwaraye-Griffin",
    "2004: April Hayes",
    "2005: Jean Marie Lester",
    "2006: Emilee Ballaro",
    "2007: Amber Linke",
    "2008: Chelsea Prophet",
    "2009: Jennifer Starkey",
    "2010: Nina Pierino",
    "2011: Desiree Wiley",
    "2012: Lonna McCary",
    "2015: Desiree Wiley (1st Runner Up)",
    "2016: Baylee Simpson (1st Runner Up)",
    "2017: Emmalee Stawicki",
    "2018: Nina Zesky",
    "2019: Taisha St. Jean",
    "2020-21: Nina Zesky",
    "2022: Amiyah King",
    "2023: Chelsea Lovell (Talent Prelim / 1st Runner Up)",
    "2024: Gianna Caetano",
  ];

  return (
    <Layout pageTitle="Legacy">
      <LegacyList>
        {titleholders.map((titleholder, index) => {
          const [year, name] = titleholder.split(": ");
          return (
            <LegacyItem key={index}>
              <strong>{year}:</strong> {name}
            </LegacyItem>
          );
        })}
      </LegacyList>
    </Layout>
  );
};

export default Legacy;
