import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import sanityClient from "../utils/sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import Layout from "../components/Layout";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
        title,
        body,
        author->{name, image},
        mainImage,
        publishedAt
      }`,
        { slug }
      )
      .then((data) => setPost(data[0]))
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == "blogPost"] | order(publishedAt desc)[0...3]{
        title,
        author->{name, image},
        mainImage,
        publishedAt,
        slug
      }`
      )
      .then((data) => setRecentPosts(data))
      .catch(console.error);
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  return (
    <Layout pageTitle={post.title}>
      <Article>
        {post.mainImage && (
          <MainImage src={urlFor(post.mainImage).url()} alt={post.title} />
        )}
        <Title>{post.title}</Title>
        <Author>
          By {post.author.name} -{" "}
          {new Date(post.publishedAt).toLocaleDateString()}
        </Author>
        <Body>
          <BlockContent blocks={post.body} />
        </Body>
        <BackLink to="/blog">Back to News</BackLink>
      </Article>
      <h2>Recent Posts</h2>
      <RecentPosts>
        {recentPosts.map((post) => (
          <PostCard key={post.slug.current}>
            {post.mainImage && (
              <CardImage src={urlFor(post.mainImage).url()} alt={post.title} />
            )}
            <CardTitle>{post.title}</CardTitle>
            <CardAuthor>
              By {post.author.name} -{" "}
              {new Date(post.publishedAt).toLocaleDateString()}
            </CardAuthor>
          </PostCard>
        ))}
      </RecentPosts>
    </Layout>
  );
};

export default Post;

// Styled Components
const Article = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const MainImage = styled.img`
  width: 100%;
  height: 400px; /* Adjust this value as needed */
  object-fit: cover;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 10px;
`;

const Author = styled.span`
  font-size: 1em;
  color: #888;
  margin-bottom: 20px;
`;

const Body = styled.div`
  font-size: 1.2em;
  line-height: 1.6;
`;

const BackLink = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  color: var(--color-yellow);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 700;
`;

const RecentPosts = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
`;

const PostCard = styled.div`
  width: 30%;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardTitle = styled.h3`
  font-size: 1.2em;
  margin: 10px;
`;

const CardAuthor = styled.span`
  font-size: 1em;
  color: #888;
  margin: 0 10px 10px;
`;
