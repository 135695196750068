import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// Import the logo images
import blackBeltBody from "../images/sponsor-logos/black-belt-body.jpg";
import buffaloCommonCouncil from "../images/sponsor-logos/buffalo-common-council.png";
import chayban from "../images/sponsor-logos/chayban.png";
import creatingEndlessOpportunities from "../images/sponsor-logos/creating-endless-opportunities.png";
import fitToReign from "../images/sponsor-logos/fit-to-reign.png";
import fullSwingSound from "../images/sponsor-logos/full-swing-sound.jpg";
import influentialWomen from "../images/sponsor-logos/influential-women.jpg";
import masten from "../images/sponsor-logos/masten.png";
import mckenzieJade from "../images/sponsor-logos/mckenzie-jade.png";
import russells from "../images/sponsor-logos/russells.png";
import tesoroSalon from "../images/sponsor-logos/tesoro-salon.png";
import university from "../images/sponsor-logos/university.jpg";

const logos = [
  blackBeltBody,
  buffaloCommonCouncil,
  chayban,
  creatingEndlessOpportunities,
  fitToReign,
  fullSwingSound,
  influentialWomen,
  masten,
  mckenzieJade,
  russells,
  tesoroSalon,
  university,
];

const Sponsors = () => {
  return (
    <SponsorsContainer>
      <Title>Sponsors & Partners</Title>
      <Description>
        Our heartfelt appreciation goes out to all our sponsors and partners,
        whose generous support plays a pivotal role in our mission. It's through
        their collaboration that we are able to provide a platform for young
        women to be empowered, achieve personal growth, and develop leadership
        skills. Their contributions make a tangible difference in the lives of
        many, and for that, we are incredibly grateful.
      </Description>
      <LogosGrid>
        {logos.map((logo, index) => (
          <LogoItem key={index}>
            <img src={logo} alt="Sponsor Logo" />
          </LogoItem>
        ))}
      </LogosGrid>
      <Subtitle>Join Our Circle of Support</Subtitle>
      <Description>
        We are always looking to broaden our horizon and welcome new sponsors to
        join our journey...
      </Description>
      <StyledLink to="/sponsor">Sponsor Opportunities</StyledLink>
    </SponsorsContainer>
  );
};

export default Sponsors;

// Styled Components
const SponsorsContainer = styled.div`
  padding: 1rem;
  text-align: center;
  width: 50%;
  margin: 3rem auto 0 auto;
`;

const Title = styled.h2`
  font-size: 3rem;
`;

const Description = styled.p`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  padding: 10px 20px;
  background-color: var(--color-yellow);
  color: var(--color-white);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 2rem;
  text-decoration: none;

  &:hover {
    background-color: var(--color-dark-gray);
  }
`;

const LogosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Adjust the minmax values as needed */
  gap: 20px; /* Adjust the gap between grid items */
  padding: 20px 0; /* Add some vertical padding */
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
`;

const LogoItem = styled.div`
  img {
    width: 100%; /* Make the logo fill the container */
    height: auto; /* Maintain the aspect ratio */
    max-width: 140px; /* Adjust based on your preference */
    max-height: 120px; /* Adjust based on your preference */
    object-fit: contain; /* Ensure logos are fully visible without being cropped */
  }
`;

const Subtitle = styled.h2`
  font-size: 2rem;
`;
