import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PostCard from "../components/PostCard";
import sanityClient from "../utils/sanityClient";
import Layout from "../components/Layout";

// Styled Components
const NewsPageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
`;

const News = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "blogPost"]{
        title,
        slug,
        author->{name, image},
        publishedAt,
        body,
        mainImage,
        categories[]->{title},
        tags
      }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  return (
    <Layout pageTitle="News">
      <NewsPageContainer>
        {posts.map((post) => (
          <PostCard key={post.slug.current} post={post} />
        ))}
      </NewsPageContainer>
    </Layout>
  );
};

export default News;
