import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

// Styled Components
const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-bottom: var(--spacing-lg);
`;

const Label = styled.label`
  margin-bottom: var(--spacing-xs);
  font-weight: bold;
`;

const Input = styled.input`
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-xs);
  border-radius: var(--border-radius);
  border: 1px solid #ccc;
  font-family: var(--font-primary);
  font-size: var(--font-size-base);

  &:focus {
    outline: none;
    border-color: var(--color-yellow);
  }
`;

const Textarea = styled.textarea`
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-xs);
  border-radius: var(--border-radius);
  border: 1px solid #ccc;
  font-family: var(--font-primary);
  font-size: var(--font-size-base);

  &:focus {
    outline: none;
    border-color: var(--color-yellow);
  }
`;

const SubmitButton = styled.input`
  padding: var(--spacing-xs);
  border-radius: var(--border-radius);
  border: none;
  background-color: var(--color-yellow);
  color: var(--color-white);
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: var(--spacing-lg);

  &:hover {
    background-color: var(--color-dark-gray);
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const Contact = () => {
  return (
    <Layout pageTitle="Contact Us">
      <div>
        <SectionTitle>Mailing</SectionTitle>
        <p>
          Miss Buffalo Scholarship Org
          <br />
          PO Box 8<br />
          Buffalo, NY 14207
        </p>
        <SectionTitle>Email</SectionTitle>
        <p>
          <a href="mailto:taisha@missbuffaloscholarship.org">
            taisha@missbuffaloscholarship.org
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default Contact;
