import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import missBuffaloImage from "../images/miss-buffalo.png";

// Styled Components
const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const ImageTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
`;

const Image = styled.img`
  width: 40%;
  height: auto;
  border-radius: var(--border-radius);
`;

const Text = styled.p`
  width: 70%;
  text-align: justify;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
`;

const MissBuffalo = () => {
  return (
    <Layout pageTitle="Meet Miss Buffalo">
      <Title>Gianna Caetano, Miss Niagara Teen 2024</Title>
      <ImageTextContainer>
        <Image src={missBuffaloImage} alt="Miss Buffalo" />
        <Text>
          Gianna Caetano is an Honors College student at the University at
          Buffalo as a double major of International Studies with a
          concentration in Latin America and Political Science with a minor in
          Spanish. When she’s not focusing on her studies, you can find her
          practicing classical piano music. Her Community Service Initiative is
          SHEro: Empowering Women. SHEro aims to empower women to find their
          strength within to become their own hero. Gianna is known for her
          heart of service, dedicating over 500 hours of community service. As a
          college student, she loves to cook nutritious meals and provide tips
          to fellow college students on how to stay healthy! Alongside playing
          tennis, this is how she stays #MissAmericaFit.
        </Text>
      </ImageTextContainer>
    </Layout>
  );
};

export default MissBuffalo;
