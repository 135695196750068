import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import image from "../images/temp.jpg";

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-lg);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: var(--spacing-lg) 0;
`;

const AboutSection = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-lg);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  padding: 0 2rem;
  margin-bottom: var(--spacing-md);
  color: var(--color-dark-blue);
`;

const Description = styled.p`
  margin: 20px 0;
  text-align: center;
  width: 70%;
  font-size: 1.2rem;
  line-height: var(--line-height-base);
  color: var(--color-dark-blue);
`;

const Button = styled(Link)`
  background-color: var(--color-yellow);
  color: var(--color-white);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-dark-gray);
  }
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 50%;
  border-radius: var(--border-radius);
  margin: 0 auto;
`;

const About = () => {
  return (
    <Container>
      <AboutSection>
        <Title>Empowering Young Women Through Scholarship and Leadership</Title>
        <Description>
          The Miss Buffalo Scholarship Organization proudly champions the cause
          of outstanding young women by providing them with unparalleled
          scholarship opportunities. Our program is meticulously designed to
          foster leadership, personal growth, and self-confidence, ensuring
          participants are well-equipped for both their present endeavors and
          future aspirations.
        </Description>
        <Button to="/about">Discover Our Journey</Button>
      </AboutSection>
      <Image src={image} alt="About Miss Buffalo" />
    </Container>
  );
};

export default About;
