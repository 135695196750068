import React from "react";
import styled from "styled-components";

// Styled Components
const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: var(--spacing-md);
`;

const CardTitle = styled.h2`
  font-size: 1.5em;
  margin: 0 0 10px;
`;

const CardDate = styled.p`
  font-size: 1em;
  color: #888;
  margin: 0 0 10px;
`;

const CardAddress = styled.p`
  font-size: 1em;
  color: #888;
  margin: 0 0 10px;
`;

const CardDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  &::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.2em;
    background: linear-gradient(to right, transparent, white 50%);
  }
`;

const EventCard = ({ event }) => {
  // Format the location object into a string
  const formatLocation = (location) => {
    const { state, city, streetAddress, postalCode } = location;
    return `${streetAddress}, ${city}, ${state}, ${postalCode}`;
  };

  return (
    <Card>
      {event.imageUrl && <CardImage src={event.imageUrl} alt={event.title} />}
      <CardContent>
        <CardTitle>{event.title}</CardTitle>
        <CardDate>
          {new Date(event.startDateTime).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </CardDate>
        {event.location && (
          <CardAddress>{formatLocation(event.location)}</CardAddress>
        )}
        <CardDescription>{event.description}</CardDescription>
      </CardContent>
    </Card>
  );
};

export default EventCard;
