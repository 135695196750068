import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../utils/sanityClient";
import { Link } from "react-router-dom";
import styled from "styled-components";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const PostCard = ({ post }) => {
  const excerpt = post.body[0].children?.[0].text;

  return (
    <Card>
      <ImageWrapper>
        {post.mainImage && (
          <Image src={urlFor(post.mainImage).url()} alt={post.title} />
        )}
      </ImageWrapper>
      <Content>
        {post.author && post.author.image && (
          <AuthorInfo>
            <AuthorImage
              src={urlFor(post.author.image).url()}
              alt={post.author.name}
            />
            <AuthorName>By {post.author.name}</AuthorName>
          </AuthorInfo>
        )}
        <PostDate>{new Date(post.publishedAt).toLocaleDateString()}</PostDate>
        <Title>{post.title}</Title>
        <Excerpt>{excerpt}</Excerpt>
        <ReadMore to={`/post/${post.slug.current}`}>Read more</ReadMore>
      </Content>
    </Card>
  );
};

export default PostCard;

// Styled Components
const Card = styled.div`
  display: flex;
  gap: 20px;
  padding: 1rem 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  min-width: 25%;
  overflow: hidden; /* Ensures the image doesn't exceed its container */
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AuthorImage = styled.img`
  width: 50px; /* Adjust based on your design */
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const AuthorName = styled.p`
  margin: 0;
  font-size: 14px;
  color: #757575;
`;

const PostDate = styled.p`
  margin: 0;
  font-size: 14px;
  color: #757575;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`;

const Excerpt = styled.p`
  position: relative;
  height: 4em; /* Adjust based on your design to fit two lines */
  overflow: hidden;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to bottom, transparent, white);
  }
`;

const ReadMore = styled(Link)`
  align-self: start;
  text-decoration: none;
  color: var(--color-yellow);
`;
