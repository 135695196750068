import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import taishaImage from "../images/board/taisha.jpg";
import dianaImage from "../images/board/diana-meyer.JPG";
import nicoleImage from "../images/board/nicole-golias.JPG";
import shaniceImage from "../images/board/shanice-tard.JPG";
import mikeImage from "../images/board/mike-read.jpg";
import selinaImage from "../images/board/selina.jpg";
import mekenzieImage from "../images/board/taisha.jpg";
import alexandraImage from "../images/board/taisha.jpg";
import miaImage from "../images/board/taisha.jpg";
import tieshekaImage from "../images/board/tiesheka-bolden.JPG";
import matthewImage from "../images/board/taisha.jpg";
import placeholder from "../images/board/placeholder.jpg";

// Styled Components
const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  margin: var(--spacing-md) 0;
`;

const TeamMember = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-md);
  align-items: center;
`;

const MemberImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

const TeamMemberInfo = styled.div`
  font-size: var(--font-size-base);
`;

const Leadership = () => {
  const teamMembers = [
    {
      name: "Taisha St. Jean",
      title: "Executive Director",
      email: "taisha@missbuffaloscholarship.org",
      headshot: taishaImage,
    },
    {
      name: "Diana Meyer",
      title: "Judges Chairwoman",
      email: "diana@missbuffaloscholarship.org",
      headshot: dianaImage,
    },
    {
      name: "Nicole Golias",
      title: "Secretary",
      email: "nicole@missbuffaloscholarship.org",
      headshot: nicoleImage,
    },
    {
      name: "Shanice Tard",
      title: "Treasurer",
      email: "shanice@missbuffaloscholarship.org",
      headshot: shaniceImage,
    },
    {
      name: "Mike Read",
      title: "Director of Marketing",
      email: "mike@missbuffaloscholarship.org",
      headshot: mikeImage,
    },
    {
      name: "Selina Irizarry",
      title: "Delegate Coordinator",
      email: "selina@missbuffaloscholarship.org",
      headshot: selinaImage,
    },
    {
      name: "MeKenzie Lund",
      title: "Director of CSI Development",
      email: "mekenzie@missbuffaloscholarship.org",
      headshot: placeholder,
    },
    {
      name: "Alexandra Rosenlund",
      title: "Director of Fundraising",
      email: "alexandra@missbuffaloscholarship.org",
      headshot: placeholder,
    },
    {
      name: "Mia Aldaco",
      title: "Board Member",
      email: "mia@missbuffaloscholarship.org",
      headshot: placeholder,
    },
    {
      name: "Tiesheka Bolden",
      title: "Board Member",
      email: "tiesheka@missbuffaloscholarship.org",
      headshot: tieshekaImage,
    },
    {
      name: "Matthew Clark",
      title: "Director of Production & Talent Preparations",
      email: "matthew@missbuffaloscholarship.org",
      headshot: placeholder,
    },
  ];

  return (
    <Layout pageTitle="Miss Buffalo Leadership Team">
      <TeamGrid>
        {teamMembers.map((member, index) => (
          <TeamMember key={index}>
            <MemberImage src={member.headshot} alt={member.name} />
            <TeamMemberInfo>
              <p>
                <strong>{member.name}</strong> - {member.title}
              </p>
              <p>
                <a href={`mailto:${member.email}`}>{member.email}</a>
              </p>
            </TeamMemberInfo>
          </TeamMember>
        ))}
      </TeamGrid>
    </Layout>
  );
};

export default Leadership;
