import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

// Styled Components
const NotFoundContainer = styled.div`
  text-align: center;
  margin: var(--spacing-lg) 0;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: var(--color-dark-gray);
`;

const NotFound = () => {
  return (
    <Layout pageTitle="Page Not Found">
      <NotFoundContainer>
        <Title>404 Not Found</Title>
        <Message>The page you're looking for doesn't exist.</Message>
      </NotFoundContainer>
    </Layout>
  );
};

export default NotFound;
