import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import About from "../components/About";
import LatestNews from "../components/LatestNews";
import PhotoLinks from "../components/PhotoLinks";
import Sponsors from "../components/Sponsors";
import Footer from "../components/Footer"; // Import Footer component
import CTA from "../components/CTA";
import AboutMissBuffalo from "../components/AboutMissBuffalo";

window.addEventListener("error", (e) => {
  if (e.message === "ResizeObserver loop limit exceeded") {
    e.stopPropagation();
    e.preventDefault();
  }
});

function Main() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <About />
      <PhotoLinks />
      <AboutMissBuffalo />
      <Sponsors />
      <LatestNews />

      <CTA />
      <Footer />
    </div>
  );
}

export default Main;
