import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EventCard from "../components/EventCard";
import sanityClient from "../utils/sanityClient";
import Layout from "../components/Layout";

// Styled Components
const RecentEvents = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  margin: var(--spacing-lg) 0;
`;

const EventsPage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "event"]{..., "imageUrl": image.asset->url}')
      .then((data) => {
        setEvents(data);
      })
      .catch(console.error);
  }, []);

  return (
    <Layout pageTitle="Upcoming Events">
      <RecentEvents>
        {events.map((event) => (
          <EventCard key={event._id} event={event} />
        ))}
      </RecentEvents>
    </Layout>
  );
};

export default EventsPage;
