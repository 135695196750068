import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Main from "./pages/Main";
import About from "./pages/About";
import BecomeAMissContestant from "./pages/BecomeAMissContestant";
import BecomeATeenContestant from "./pages/BecomeATeenContestant";
import Contact from "./pages/Contact";
import Donate from "./pages/Donate";
import Leadership from "./pages/Leadership";
import MissBuffalo from "./pages/MissBuffalo";
import MissBuffaloTeen from "./pages/MissBuffaloTeen";
import MissNiagara from "./pages/MissNiagara";
import MissNiagaraTeen from "./pages/MissNiagaraTeen";
import News from "./pages/News";
import Volunteer from "./pages/Volunteer";
import Sponsor from "./pages/Sponsor";
import NotFound from "./pages/NotFound";
import QueenCityClub from "./pages/QueenCityClub";
import Legacy from "./pages/Legacy";
import Events from "./pages/Events";
import Post from "./components/Post";
import EventDetails from "./components/EventDetails";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/become-a-miss-contestant"
          element={<BecomeAMissContestant />}
        />
        <Route
          path="/become-a-teen-contestant"
          element={<BecomeATeenContestant />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/leadership" element={<Leadership />} />
        <Route path="/miss-buffalo" element={<MissBuffalo />} />
        <Route path="/miss-buffalo-teen" element={<MissBuffaloTeen />} />
        <Route path="/miss-niagara" element={<MissNiagara />} />
        <Route path="/miss-niagara-teen" element={<MissNiagaraTeen />} />
        <Route path="/news" element={<News />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/sponsor" element={<Sponsor />} />
        <Route path="/queen-city-club" element={<QueenCityClub />} />
        <Route path="/legacy" element={<Legacy />} />
        <Route path="/events" element={<Events />} />
        <Route path="/post/:slug" element={<Post />} />
        <Route path="/event/:slug" element={<EventDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
