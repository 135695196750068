import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import sanityClient from "../utils/sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import styled from "styled-components";
import Layout from "../components/Layout";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

// Styled Components
const EventDetailsContainer = styled.div`
  padding: var(--spacing-lg);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: var(--spacing-lg) 0;
`;

const EventTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: var(--spacing-md);
`;

const EventImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-md);
`;

const EventDetail = styled.p`
  font-size: 1.2rem;
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-md);
`;

const OrganizerImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: var(--spacing-md);
`;

const EventDetails = () => {
  const { slug } = useParams();
  const [eventData, setEvent] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "event" && slug.current == $slug]{
        title,
        description,
        startDateTime,
        endDateTime,
        location,
        category,
        organizer->{name, image},
        tags,
        image
      }`,
        { slug }
      )
      .then((data) => setEvent(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!eventData) return <div>Loading...</div>;

  return (
    <Layout pageTitle={eventData.title}>
      <EventDetailsContainer>
        <EventTitle>{eventData.title}</EventTitle>
        {eventData.image && (
          <EventImage
            src={urlFor(eventData.image).url()}
            alt={eventData.title}
          />
        )}
        <EventDetail>{eventData.description}</EventDetail>
        <EventDetail>
          {new Date(eventData.startDateTime).toLocaleDateString()} -{" "}
          {new Date(eventData.endDateTime).toLocaleDateString()}
        </EventDetail>
        <EventDetail>Location: {eventData.location}</EventDetail>
        <EventDetail>Category: {eventData.category}</EventDetail>
        <EventDetail>Organizer: {eventData.organizer.name}</EventDetail>
        {eventData.organizer.image && (
          <OrganizerImage
            src={urlFor(eventData.organizer.image).url()}
            alt={eventData.organizer.name}
          />
        )}
        <EventDetail>Tags: {eventData.tags.join(", ")}</EventDetail>
      </EventDetailsContainer>
      <Link to="/events" className="backLink">
        Back to Events
      </Link>
    </Layout>
  );
};

export default EventDetails;
