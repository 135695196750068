import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

// Styled Components
const SectionTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-md);
`;

const AboutContainer = styled.div`
  padding: var(--spacing-lg);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const About = () => {
  return (
    <Layout pageTitle="About Miss Buffalo">
      <AboutContainer>
        <SectionTitle>Our Mission</SectionTitle>
        <Paragraph>
          Our mission is to empower young women to strive for excellence when
          pursuing personal and professional goals while becoming instruments of
          change within their communities.
        </Paragraph>
        <Paragraph>
          The Miss Buffalo Program has produced women who have gone on to become
          Engineers, Music industry entertainers, Business professionals,
          Full-time moms, Professional cheerleaders, Marketing executives,
          Clerks for the Georgia Supreme court, Actresses, MBA recipients, and
          Entrepreneurs.
        </Paragraph>
        <SectionTitle>Our Vision</SectionTitle>
        <Paragraph>
          Through our work with the Miss America Opportunity, we want to
          increase opportunities for young women to further their educational
          and professional pursuits. The Miss Buffalo Competition is a licensee
          of the Miss America Opportunity and is designed to provide scholarship
          opportunities to young women throughout the WNY area.
        </Paragraph>
      </AboutContainer>
    </Layout>
  );
};

export default About;
