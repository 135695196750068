import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import hero1 from "../images/hero/1.png";
import hero2 from "../images/hero/2.png";
import hero3 from "../images/hero/3.png";
import hero4 from "../images/hero/4.png";
import hero5 from "../images/hero/5.png";

import { FaTrophy, FaDonate } from "react-icons/fa";

// Array of images for the slideshow
const images = [hero1, hero2, hero3, hero4, hero5];

// Styled Components
const HeroContainer = styled.div`
  position: relative;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom; /* Show the bottom portion of the image */
  transition: opacity 1.5s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Darker overlay for better readability */
  z-index: 1;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;

const HeaderText = styled.h1`
  font-size: 4rem; /* Increased size */
  font-weight: 800; /* Heavier font weight */
  color: white;
  margin-bottom: 10px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Add text shadow */
`;

const SubText = styled.p`
  font-size: 2rem; /* Increased size */
  font-weight: 600; /* Heavier font weight */
  color: white;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Add text shadow */
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Button = styled(Link)`
  background-color: var(--color-yellow);
  color: var(--color-white);
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-dark-gray);
  }

  svg {
    margin-right: 10px;
  }
`;

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <HeroContainer>
      {images.map((image, index) => (
        <Slide
          key={index}
          style={{ backgroundImage: `url(${image})` }}
          isVisible={index === currentSlide}
        ></Slide>
      ))}
      <Overlay />
      <ContentContainer>
        <HeaderText>Shaping the Leaders of Tomorrow</HeaderText>
        <SubText>Join Us in Empowering the Next Generation of Women</SubText>
        <Buttons>
          <Button to="/become-a-miss-contestant">
            <FaTrophy /> Become a Miss Contestant
          </Button>
          <Button to="/become-a-teen-contestant">
            <FaTrophy /> Become a Teen Contestant
          </Button>
          <Button to="/donate">
            <FaDonate /> Donate
          </Button>
        </Buttons>
      </ContentContainer>
    </HeroContainer>
  );
};

export default Hero;
