import React from "react";
import styled from "styled-components";
import { FaBrain, FaHeart, FaStar } from "react-icons/fa";
import atWork from "../images/at-work.jpg";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  background: var(--color-yellow);
  text-align: center;
  background-image: url("../images/repeat-background.jpg");
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
`;

const FeatureImage = styled.img`
  width: 50%;
  height: auto;
  border-radius: var(--border-radius);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: var(--color-dark);
  margin: 3rem auto 2rem auto;
`;

const MissionStatement = styled.p`
  font-size: 1.2rem;
  color: var(--color-dark-gray);
  margin-bottom: 40px;
  padding: 1rem 6rem;
`;

const Principles = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin: 0 2rem;
`;

const PrincipleCard = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid var(--color-light-gray);
  border-radius: var(--border-radius);
  background: var(--color-white);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Icon = styled.div`
  font-size: 3rem;
  color: var(--color-primary);
  margin-bottom: 20px;
`;

const PrincipleTitle = styled.h3`
  font-size: 1.5rem;
  color: var(--color-dark);
  margin-bottom: 10px;
`;

const PrincipleDescription = styled.p`
  font-size: 1rem;
  color: var(--color-dark-gray);
`;

const AboutMissBuffalo = () => {
  return (
    <Container>
      <ContentWrapper>
        <FeatureImage src={atWork} alt="Miss Buffalo" />
        <div>
          <Title>More Than a Pageant</Title>
          <MissionStatement>
            The Miss Buffalo Scholarship Organization is much more than a beauty
            pageant; it is a transformative experience that champions the
            empowerment of young women. At its core, our mission is to inspire
            participants to reach their highest personal and professional
            potential while making a lasting impact on their communities. This
            holistic approach to empowerment encourages not only the pursuit of
            individual excellence but also fosters a deep sense of
            responsibility toward societal betterment.
          </MissionStatement>
        </div>
      </ContentWrapper>
      <Principles>
        <PrincipleCard>
          <Icon as={FaBrain} />
          <PrincipleTitle>Intellectual Growth</PrincipleTitle>
          <PrincipleDescription>
            Dive into a world where curiosity leads to endless discovery and
            personal evolution. The Miss Buffalo Scholarship Organization
            celebrates the pursuit of knowledge, pushing boundaries to foster a
            lifelong passion for learning and growth.
          </PrincipleDescription>
        </PrincipleCard>
        <PrincipleCard>
          <Icon as={FaHeart} />
          <PrincipleTitle>Community Impact</PrincipleTitle>
          <PrincipleDescription>
            Be a beacon of change and positivity in your community, turning
            compassion into action. Join us in the Miss Buffalo Scholarship
            Organization to contribute to meaningful initiatives and uplift
            those around us through collective effort.
          </PrincipleDescription>
        </PrincipleCard>
        <PrincipleCard>
          <Icon as={FaStar} />
          <PrincipleTitle>Excellence in Pursuit</PrincipleTitle>
          <PrincipleDescription>
            Strive for greatness in every endeavor, inspiring others with your
            commitment and resilience. The Miss Buffalo Scholarship Organization
            supports your journey towards achieving your highest potential,
            celebrating every milestone with integrity and passion.
          </PrincipleDescription>
        </PrincipleCard>
      </Principles>
    </Container>
  );
};

export default AboutMissBuffalo;
