import React, { useState, useEffect } from "react";
import LatestNewsCard from "../components/LatestNewsCard";
import sanityClient from "../utils/sanityClient";
import styled from "styled-components";

// Styled Components
const LatestNewsContainer = styled.div`
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h2`
  margin: 3rem auto 2rem auto;
  text-align: center;
  font-size: 3rem;
`;

const NewsCards = styled.div`
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* Adjust this for responsiveness as needed */
  gap: 20px; /* This creates space between the cards */
`;

const LatestNews = () => {
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "blogPost"] | order(publishedAt desc)[0...5]{
        title,
        slug,
        author->{name, image},
        publishedAt,
        body,
        mainImage,
        categories[]->{title},
        tags
      }`
      )
      .then((data) => setLatestPosts(data))
      .catch(console.error);
  }, []);

  return (
    <LatestNewsContainer>
      <Title>Latest News</Title>
      <NewsCards>
        {latestPosts.slice(0, 6).map((post) => (
          <LatestNewsCard key={post.slug.current} post={post} />
        ))}
      </NewsCards>
    </LatestNewsContainer>
  );
};

export default LatestNews;
