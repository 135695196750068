import React, { useEffect, useState } from "react";
import Header from "./Header";
import CTA from "./CTA";
import Footer from "./Footer";
import styled from "styled-components";
import backgroundImage1 from "../images/headers/1.png";
import backgroundImage2 from "../images/headers/2.png";
import backgroundImage3 from "../images/headers/3.png";
import backgroundImage4 from "../images/headers/4.png";
import backgroundImage5 from "../images/headers/5.png";
import backgroundImage6 from "../images/headers/6.png";

// List of background images
const backgroundImages = [
  backgroundImage1,
  backgroundImage2,
  backgroundImage3,
  backgroundImage4,
  backgroundImage5,
  backgroundImage6,
];

// Function to get a random background image
const getRandomBackgroundImage = () => {
  return backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
};

// Styled Components
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Heading = styled.h1`
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url(${(props) => props.backgroundImage});
  background-size: cover;

  width: 100%;
  font-size: 2.5rem; /* Adjust as needed */
  color: white; /* Adjust as needed */
  text-align: center;
  padding: 5rem 0; /* Adjust as needed */
  margin-top: 0; /* Add this line */
`;

const MainContent = styled.main`
  margin: 0 auto;
  width: 50%;
  flex-grow: 1;
`;

const Layout = ({ children, pageTitle }) => {
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    setBackgroundImage(getRandomBackgroundImage());
  }, []);

  return (
    <LayoutContainer>
      <Header />
      <Heading backgroundImage={backgroundImage}>{pageTitle}</Heading>
      <MainContent>{children}</MainContent>
      <CTA />
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
