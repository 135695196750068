import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

// Styled Components
const Container = styled.div`
  padding: var(--spacing-lg);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: var(--spacing-lg) 0;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: var(--line-height-base);
  color: var(--color-dark-blue);
  margin-bottom: var(--spacing-md);
`;

const Volunteer = () => {
  return (
    <Layout pageTitle="Volunteer with Us">
      <Container>
        <Title>Volunteer with Us</Title>
        <Paragraph>
          We are always looking for passionate individuals who are willing to
          dedicate their time and skills to help us achieve our mission. As a
          volunteer, you will have the opportunity to make a significant impact
          in the lives of young women and contribute to the success of our
          programs and events.
        </Paragraph>
        <Paragraph>
          Whether you have a few hours to spare or are looking for a long-term
          commitment, we have a variety of volunteer opportunities to suit your
          availability and interests. From event planning and coordination to
          mentorship and administrative support, your contributions will be
          highly valued and appreciated.
        </Paragraph>
        <Paragraph>
          If you are interested in volunteering with us, please reach out to us
          at{" "}
          <a href="mailto:volunteer@missbuffaloscholarship.org">
            volunteer@missbuffaloscholarship.org
          </a>{" "}
          with your name, contact information, and areas of interest. We look
          forward to welcoming you to our team and working together to empower
          the next generation of leaders.
        </Paragraph>
      </Container>
    </Layout>
  );
};

export default Volunteer;
