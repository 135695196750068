import React from "react";
import styled from "styled-components";
// Import images
import roleImg from "../images/role.jpg";
import programImg from "../images/program.jpg";
import competitionImg from "../images/the-competition.jpg";

const photos = [
  { title: "The Program", link: "/program", img: programImg },
  { title: "The Competition", link: "/competition", img: competitionImg },
  { title: "The Role", link: "/role", img: roleImg },
];

const PhotoLinksContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  background: var(--color-yellow);
`;

const PhotoLink = styled.a`
  position: relative;
  width: 33.33%;
  overflow: hidden;
  text-decoration: none;
`;

const Image = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: transform 0.3s ease;
  filter: grayscale(100%);
  &:hover {
    transform: scale(1.1);
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--color-yellow);
  padding: 10px;
  transition: background 0.3s ease;
  background: rgba(0, 0, 0, 0.9);
  font-size: 1.5rem;
  ${PhotoLink}:hover & {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const PhotoLinks = () => {
  return (
    <PhotoLinksContainer>
      {photos.map((photo, index) => (
        <PhotoLink key={index} href={photo.link}>
          <Image src={photo.img} alt={photo.title} />
          <Title>{photo.title}</Title>
        </PhotoLink>
      ))}
    </PhotoLinksContainer>
  );
};

export default PhotoLinks;
