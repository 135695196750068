import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

// Styled Components
const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
  text-align: center;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-md);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: var(--line-height-base);
  margin-bottom: var(--spacing-md);
`;

const LinkStyled = styled.a`
  color: var(--color-yellow);
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: var(--color-dark-gray);
  }
`;

const Sponsor = () => {
  return (
    <Layout pageTitle="Become A Sponsor">
      <Title>Partner With Us</Title>
      <Paragraph>
        Thank you for your interest in becoming a Miss Buffalo Partner! Our
        organization offers a wide variety of ways to get involved and numerous
        perks that come with your support.
      </Paragraph>
      <Subtitle>Unique Opportunities</Subtitle>
      <Paragraph>
        If you have something unique in mind or want to explore our perk
        options, please contact our Director of Fundraising at{" "}
        <LinkStyled href="mailto:alexandra@missbuffaloscholarship.org">
          alexandra@missbuffaloscholarship.org
        </LinkStyled>
        .
      </Paragraph>
      <Subtitle>Sponsors & Partners</Subtitle>
      <Paragraph>
        At Miss Buffalo, we extend our deepest gratitude to our esteemed
        sponsors and partners, whose unwavering support is fundamental to our
        mission. Their contributions not only empower us to offer substantial
        scholarship opportunities but also enable us to make a significant
        impact within the community through various engagement initiatives. Our
        sponsors and partners play a crucial role in the personal and
        professional development of our participants, helping to shape the
        leaders of tomorrow.
      </Paragraph>
      <Subtitle>Join Our Circle of Support</Subtitle>
      <Paragraph>
        We are always looking to broaden our horizon and welcome new sponsors to
        join our journey. By partnering with us, you're not just sponsoring an
        event; you're investing in the future of young women, education, and
        community betterment. We offer a range of sponsorship packages, each
        providing valuable benefits and exposure opportunities. If you believe
        in empowering young individuals and making a positive impact in the
        community, we would love to hear from you. Please get in touch to
        explore how we can collaborate to make a difference.
      </Paragraph>
      <Subtitle>Simple Donations</Subtitle>
      <Paragraph>
        Want to make a simple donation without all the huff around sponsorship?
        You can easily donate through Venmo or Paypal. See the options below:
      </Paragraph>
      <Paragraph>
        Venmo:{" "}
        <LinkStyled
          href="https://account.venmo.com/u/missbuffalo"
          target="blank"
        >
          @missbuffalo
        </LinkStyled>
      </Paragraph>
      <Paragraph>
        Paypal:{" "}
        <LinkStyled href="https://paypal.me/missbuffalonyorg" target="blank">
          paypal.me/missbuffalonyorg
        </LinkStyled>
      </Paragraph>
    </Layout>
  );
};

export default Sponsor;
