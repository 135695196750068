import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

// Styled Components
const Container = styled.div`
  padding: var(--spacing-lg);
  background-color: var(--color-light-gray);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: var(--spacing-lg) 0;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: var(--line-height-base);
  color: var(--color-dark-blue);
  margin-bottom: var(--spacing-md);
`;

const EmailLink = styled.a`
  color: var(--color-yellow);
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: var(--color-dark-gray);
  }
`;

const QueenCityClub = () => {
  return (
    <Layout pageTitle="Queen City Club">
      <Container>
        <Paragraph>
          We are excited to announce the Miss Buffalo and Miss Niagara Queen
          City Club! The purpose of the Queen City Club is to enlist the support
          of 100 donors willing to contribute $50 to provide guaranteed
          underwriting for the winners’ $5,000 scholarship awarded during the
          Miss Buffalo and Miss Niagara Scholarship Competition on Saturday,
          November 12, 2022! This exclusive membership will not exceed 100
          supporters and there will be no honorary members.
        </Paragraph>
        <Paragraph>
          Only those 100 donors who contribute $50 will become members of the
          Miss Buffalo and Miss Niagara Queen City Club, making it exclusive to
          those that support our mission to provide an exceptional platform for
          young women to have access, visibility, resources, and inclusion in
          opportunities to be awarded funding for education, experiential
          entrepreneurship, and career advancement. In addition, members will
          receive discounted tickets for various events throughout the year.
        </Paragraph>
        <Paragraph>
          As a member of the Queen City Club, 100% of your donation will be
          earmarked for the Miss Buffalo and Miss Niagara’s scholarship award.
          In exchange for your generosity, your name will be listed in the
          special Queen City Club section of our website and the program book.
          This is an opportunity to honor yourself or someone else that has
          played a pivotal role in the advancement and empowerment of young
          women.
        </Paragraph>
        <Paragraph>
          This scholarship, along with hundreds of dollars in additional
          scholarships, will be awarded to young women who are shaping our
          communities with their service and leadership. We are pleased to
          provide this scholarship, along with many others, to help our
          candidates continue their academic journeys.
        </Paragraph>
        <Paragraph>
          Email:{" "}
          <EmailLink href="mailto:alexandra@missbuffaloscholarship.org">
            alexandra@missbuffaloscholarship.org
          </EmailLink>{" "}
          and{" "}
          <EmailLink href="mailto:taisha@missbuffaloscholarship.org">
            taisha@missbuffaloscholarship.org
          </EmailLink>{" "}
          to join.
        </Paragraph>
      </Container>
    </Layout>
  );
};

export default QueenCityClub;
