import React from "react";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../utils/sanityClient";
import { Link } from "react-router-dom";
import styled from "styled-components";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

// Styled Components
const PostCardContainer = styled(Link)`
  display: block;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  text-decoration: none; /* Remove link underlines */
  color: inherit; /* Use the text color of parent */
  background: white;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const PostImageWrapper = styled.div`
  width: 100%;
  overflow: hidden; /* Ensures the image doesn't exceed its container */
`;

const PostImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: auto;
  max-height: 200px; /* Adjust based on your design */
`;

const PostContent = styled.div`
  padding: 15px;
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AuthorImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const PostTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
`;

const PostExcerpt = styled.p`
  position: relative;
  height: 4.5em; /* Adjust based on line-height to fit three lines */
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.5em; /* Roughly half the height of the text block to create a fading effect */
    background: linear-gradient(to bottom, transparent, white);
  }
`;

const LatestNewsCard = ({ post }) => {
  // Extracts and concatenates the first few words for an excerpt. Adjust according to your data structure
  const excerpt =
    post.body
      .map((block) => block.children?.map((child) => child.text).join(" "))
      .join(" ")
      .split(" ")
      .slice(0, 50) // Adjust the number of words as needed
      .join(" ") + "...";

  return (
    <PostCardContainer to={`/post/${post.slug.current}`}>
      <PostImageWrapper>
        {post.mainImage && (
          <PostImage src={urlFor(post.mainImage).url()} alt={post.title} />
        )}
      </PostImageWrapper>
      <PostContent>
        {post.author && post.author.image && (
          <AuthorInfo>
            <AuthorImage
              src={urlFor(post.author.image).url()}
              alt={post.author.name}
            />
            <p>By {post.author.name}</p>
          </AuthorInfo>
        )}
        <PostTitle>{post.title}</PostTitle>
        <PostExcerpt>{excerpt}</PostExcerpt>
      </PostContent>
    </PostCardContainer>
  );
};

export default LatestNewsCard;
