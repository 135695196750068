import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import missNiagaraImage from "../images/miss-niagara.png";

// Styled Components
const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const ImageTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
`;

const Image = styled.img`
  width: 40%;
  height: auto;
  border-radius: var(--border-radius);
`;

const Text = styled.p`
  width: 70%;
  text-align: justify;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
`;

const MissNiagara = () => {
  return (
    <Layout pageTitle="Meet Miss Niagara">
      <Title>Scarlett De Witt, Miss Niagara Teen 2024</Title>
      <ImageTextContainer>
        <Image src={missNiagaraImage} alt="Miss Niagara" />
        <Text>
          Scarlett De Witt is a graduate from SUNY Adirondack with a degree in
          Political Science. She plans to receive her bachelors degree from the
          University at Albany in Political Science with a concentration in
          American Government and a minor in Japanese. Her future aspiration is
          to become the first female President of the United States of America.
          In 2022 she ran for NYS Assembly’s 113th District and is currently the
          youngest member, by over 30 years, to be appointed to the Glens Falls
          Board of Public Safety. She wrestled on an all male varsity team in
          high school and was the first female wrestler in her county. She was
          also the first female wrestler at her college. She is dedicated
          towards breaking the glass ceiling with gender stereotypes. “If men
          can do it, so can we!” She enjoys scuba diving, traveling the world,
          and being a total foodie! In her free time she is an advocate for
          vitiligo awareness, volunteers with Meals On Wheels, The Open Door
          Mission; a homeless shelter and soup kitchen, Big Brothers Big
          Sisters, PKA Connect, and much more. She is incredibly passionate
          about her Community Service Initiative ‘Girls Growing Government’
          which gets girls interested and involved in politics through
          empowerment, education, and presenting opportunity. “If we are 51% of
          the population, we must be 51% of the representation. So let’s get
          political!”
        </Text>
      </ImageTextContainer>
    </Layout>
  );
};

export default MissNiagara;
