import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa";
import crownLogo from "../images/crown.jpg";

// Styled Components
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-dark-gray);
  padding: 0 3rem;
  position: relative;

  @media (max-width: 1326px) {
    padding: 0 1rem;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Logo = styled.img`
  height: 50px;
`;

const LogoText = styled.span`
  color: var(--color-yellow);
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 1326px) {
    display: none;
  }
`;

const Nav = styled.nav`
  display: flex;

  @media (max-width: 1326px) {
    display: none;
    flex-direction: column;
    background-color: var(--color-dark-gray);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-top: 1px solid #ccc;

    ${(props) =>
      props.isOpen &&
      `
      display: flex;
    `}
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1326px) {
    flex-direction: column;
  }
`;

const NavItem = styled.li`
  margin-left: 20px;
  position: relative;

  @media (max-width: 1326px) {
    margin: 0;
    border-bottom: 1px solid #ccc;
  }

  a {
    color: var(--color-white);
    text-decoration: none;
    font-size: 14px;
    padding: 0.5rem 1rem;
    display: block;
    transition: color 0.3s ease;

    &:hover {
      color: var(--color-yellow);
    }

    @media (max-width: 1326px) {
      padding: 1rem;
    }
  }

  &:hover > ul {
    display: block;
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 1326px) {
    &:hover > ul {
      display: none;
    }
  }
`;

const DropdownMenu = styled.ul`
  display: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-dark-gray);
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: center;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  border-radius: 15px;
  transition: visibility 0.2s, opacity 0.2s linear;

  a {
    color: var(--color-white);
    text-decoration: none;
    padding: 10px 20px;
    display: block;
    transition: color 0.3s;

    &:hover {
      color: var(--color-yellow);
    }
  }

  @media (max-width: 1326px) {
    position: static;
    transform: none;
    opacity: 1;
    visibility: visible;
    background-color: var(--color-dark-gray);
    display: block;
    box-shadow: none;
    border-radius: 0;
    padding: 0;

    a {
      padding: 1rem;
    }
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--color-white);

  @media (max-width: 1326px) {
    display: block;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <LogoContainer to="/">
        <Logo src={crownLogo} alt="Miss Buffalo Logo" />
        <LogoText>Miss Buffalo Scholarship Organization</LogoText>
      </LogoContainer>
      <HamburgerMenu onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </HamburgerMenu>
      <Nav isOpen={isOpen}>
        <NavList>
          <NavItem>
            <Link to="/">HOME</Link>
          </NavItem>
          <NavItem>
            <Link to="#">TITLEHOLDERS</Link>
            <DropdownMenu>
              <li>
                <Link to="/miss-buffalo">Miss Buffalo</Link>
              </li>
              <li>
                <Link to="/miss-buffalo-teen">Miss Buffalo Teen</Link>
              </li>
              <li>
                <Link to="/miss-niagara">Miss Niagara</Link>
              </li>
              <li>
                <Link to="/miss-niagara-teen">Miss Niagara Teen</Link>
              </li>
            </DropdownMenu>
          </NavItem>
          <NavItem>
            <Link to="/events">EVENTS</Link>
          </NavItem>
          <NavItem>
            <Link to="#">GET INVOLVED</Link>
            <DropdownMenu>
              <li>
                <Link to="/become-a-miss-contestant">
                  Become a Miss Contestant
                </Link>
              </li>
              <li>
                <Link to="/become-a-teen-contestant">
                  Become a Teen Contestant
                </Link>
              </li>
              <li>
                <Link to="/queen-city-club">Queen City Club</Link>
              </li>
              <li>
                <Link to="/volunteer">Volunteer</Link>
              </li>
              <li>
                <Link to="/donate">Donate</Link>
              </li>
            </DropdownMenu>
          </NavItem>
          <NavItem>
            <Link to="#">ABOUT</Link>
            <DropdownMenu>
              <li>
                <Link to="/about">About Miss Buffalo</Link>
              </li>
              <li>
                <Link to="/leadership">Leadership Team</Link>
              </li>
              <li>
                <Link to="/legacy">Legacy</Link>
              </li>
            </DropdownMenu>
          </NavItem>
          <NavItem>
            <Link to="/news">NEWS</Link>
          </NavItem>
          <NavItem>
            <Link to="/contact">CONTACT</Link>
          </NavItem>
        </NavList>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
