import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled Components
const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-yellow);
  padding: 4rem 0;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
`;

const CtaText = styled.h2`
  margin: 0;
  flex-grow: 1;
  text-align: left;
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 800;
`;

const CtaButton = styled(Link)`
  background-color: transparent;
  color: var(--color-dark-gray);
  border: 2px solid var(--color-dark-gray);
  width: 200px;
  cursor: pointer;
  padding: 1rem;
  font-size: 1.25rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-dark-gray);
    color: var(--color-yellow);
  }
`;

const CTA = () => {
  return (
    <CtaContainer>
      <ContentContainer>
        <CtaText>Interested in Getting Involved with Miss Buffalo?</CtaText>
        <CtaButton to="/contact">Get Started</CtaButton>
      </ContentContainer>
    </CtaContainer>
  );
};

export default CTA;
