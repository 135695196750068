import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useForm, ValidationError } from "@formspree/react";

// Styled Components
const FormContainer = styled.div`
  text-align: center;
  margin: 2rem auto;
  width: 80%;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: var(--color-dark-gray);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: var(--color-dark-gray);
  margin-bottom: 2rem;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  margin: 0 auto;
`;

const Input = styled(Field)`
  width: 100%;
  margin: auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
`;

const TextArea = styled(Input).attrs({ as: "textarea" })`
  height: 100px;
`;

const RadioGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 1rem;
`;

const RadioLabel = styled.label`
  font-size: 1rem;
  color: var(--color-dark-gray);
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: var(--color-yellow);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 3rem;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: var(--color-dark-gray);
  }
`;

const ErrorMessageStyled = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: -15px;
  margin-bottom: 15px;
`;

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  schoolName: Yup.string().required("Required"),
  phoneNumber: Yup.string().matches(
    /^(\+\d{1,3}[- ]?)?\d{10}$/,
    "Phone number is not valid"
  ),
  age: Yup.number().required("Required").positive().integer(),
  talent: Yup.string().required("Required"),
  delegateCategory: Yup.string().required("Required"),
  parentsNames: Yup.string().required("Required"),
});

const MissDelegateForm = () => {
  const [state, handleSubmit] = useForm("xovaqona");

  if (state.succeeded) {
    return (
      <strong>
        Thank you for your submission. We will reach out to you soon with more
        information and next steps!
      </strong>
    );
  }

  return (
    <FormContainer>
      <Heading>Become a Miss Contestant</Heading>
      <Paragraph>
        Please fill out the form below and a representative will get back to you
        with more information.
      </Paragraph>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          homeAddress: "",
          schoolName: "",
          phoneNumber: "",
          age: "",
          talent: "",
          serviceInitiative: "",
          delegateCategory: "",
          parentsNames: "",
          parentsAddress: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <Input type="text" name="fullName" placeholder="Full Name" />
            <ErrorMessage name="fullName" component={ErrorMessageStyled} />

            <Input type="email" name="email" placeholder="Email" />
            <ErrorMessage name="email" component={ErrorMessageStyled} />

            <TextArea name="homeAddress" placeholder="Home Address" />
            <ErrorMessage name="homeAddress" component={ErrorMessageStyled} />

            <Input type="text" name="schoolName" placeholder="School Name" />
            <ErrorMessage name="schoolName" component={ErrorMessageStyled} />

            <Input type="tel" name="phoneNumber" placeholder="Phone Number" />
            <ErrorMessage name="phoneNumber" component={ErrorMessageStyled} />

            <Input type="number" name="age" placeholder="Age" />
            <ErrorMessage name="age" component={ErrorMessageStyled} />

            <Input type="text" name="talent" placeholder="Talent" />
            <ErrorMessage name="talent" component={ErrorMessageStyled} />

            <TextArea
              name="serviceInitiative"
              placeholder="Service Initiative"
            />
            <ErrorMessage
              name="serviceInitiative"
              component={ErrorMessageStyled}
            />

            <RadioGroup>
              <RadioLabel>
                <Field type="radio" name="delegateCategory" value="Teen" />
                Teen
              </RadioLabel>
              <RadioLabel>
                <Field type="radio" name="delegateCategory" value="Miss" />
                Miss
              </RadioLabel>
            </RadioGroup>
            <ErrorMessage
              name="delegateCategory"
              component={ErrorMessageStyled}
            />

            <Input
              type="text"
              name="parentsNames"
              placeholder="Parent’s Names"
            />
            <ErrorMessage name="parentsNames" component={ErrorMessageStyled} />

            <TextArea name="parentsAddress" placeholder="Parent's Address" />
            <ErrorMessage
              name="parentsAddress"
              component={ErrorMessageStyled}
            />

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </StyledForm>
        )}
      </Formik>
    </FormContainer>
  );
};

export default MissDelegateForm;
