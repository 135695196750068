import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import missBuffaloTeenImage from "../images/miss-buffalo-teen.png";

// Styled Components
const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
`;

const ImageTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
`;

const Image = styled.img`
  width: 40%;
  height: auto;
  border-radius: var(--border-radius);
`;

const Text = styled.p`
  width: 70%;
  text-align: justify;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
`;

const MissBuffaloTeen = () => {
  return (
    <Layout pageTitle="Meet Miss Buffalo Teen">
      <Title>Gillian Vit, Miss Niagara Teen 2024</Title>
      <ImageTextContainer>
        <Image src={missBuffaloTeenImage} alt="Miss Buffalo Teen" />
        <Text>
          Quite literally a student of music since before I was born, and
          enrolling in my first music class at the Hochstein school at age 4
          weeks, I began my formal violin training at age 3 and currently hold
          leadership chairs in my school orchestra and the Hochstein Youth
          Symphony Orchestra that regularly tours Europe. I'm also a Varsity
          Scholar Athlete, with my favorite school sport being tennis, and a
          competitive gymnast turned gymnastics coach. I also love giving back
          to my community by sharing my talents in both Music and Art. I hope to
          attend Juilliard and major in Violin Performance to eventually become
          a violin soloist, violinist in a professional orchestra, and a
          professor who teaches violin and studies the effects of music on the
          brain.
        </Text>
      </ImageTextContainer>
    </Layout>
  );
};

export default MissBuffaloTeen;
