import React from "react";
import styled from "styled-components";
import crown from "../images/crown.jpg";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

const socials = [
  { name: <FaTwitter />, link: "https://twitter.com" },
  { name: <FaInstagram />, link: "https://instagram.com" },
  { name: <FaYoutube />, link: "https://youtube.com" },
  { name: <FaFacebookF />, link: "https://facebook.com" },
];

const links = [
  {
    title: "Home",
    items: ["Overview", "The Competition", "Step to the Stage"],
  },
  { title: "Our History", items: ["Press Credentials", "Book Miss Buffalo"] },
  {
    title: "Donate",
    items: ["Privacy Policy", "Contact Us", "Grievance Form", "Partnerships"],
  },
];

// Styled Components
const FooterContainer = styled.footer`
  padding: var(--spacing-lg);
  text-align: center;
  background-color: var(--color-dark-gray);
  color: var(--color-yellow);
`;

const CrownImage = styled.img`
  width: 100px;
  margin: 0 auto;
  display: block;
  margin-bottom: 0.5rem;
`;

const ContactButton = styled.button`
  padding: 10px 20px;
  margin-bottom: 2rem;
  background-color: transparent;
  color: var(--color-yellow);
  border: 1px solid var(--color-yellow);
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-yellow);
    color: var(--color-dark-gray);
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SocialIcon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-yellow);
  color: var(--color-dark-gray);
  padding: 0.25rem;
  margin: 0 0.5rem;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: var(--color-dark-gray);
    color: var(--color-yellow);
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 20px auto;
  width: 50%;
`;

const LinkGroup = styled.div`
  width: calc(33.33% - 20px);
`;

const LinkTitle = styled.h3`
  margin-bottom: 10px;
  color: var(--color-white);
  font-weight: bold;
`;

const FooterLink = styled.a`
  display: block;
  margin-bottom: 5px;
  color: var(--color-yellow);
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: var(--color-white);
  }
`;

const Copy = styled.p`
  font-size: 0.8em;
  color: var(--color-grey);
`;

const Footer = () => {
  return (
    <FooterContainer>
      <CrownImage src={crown} alt="Miss Buffalo Crown" />
      <ContactButton>Contact Us</ContactButton>
      <SocialsContainer>
        {socials.map((social, index) => (
          <SocialIcon key={index} href={social.link}>
            {social.name}
          </SocialIcon>
        ))}
      </SocialsContainer>
      <LinksContainer>
        {links.map((link, index) => (
          <LinkGroup key={index}>
            <LinkTitle>{link.title}</LinkTitle>
            {link.items.map((item, itemIndex) => (
              <FooterLink key={itemIndex} href="#">
                {item}
              </FooterLink>
            ))}
          </LinkGroup>
        ))}
      </LinksContainer>
      <Copy>
        © {new Date().getFullYear()} Miss Buffalo Scholarship Organization
      </Copy>
    </FooterContainer>
  );
};

export default Footer;
