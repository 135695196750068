import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  padding: 2rem;
`;

const Heading = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  text-align: center;
  margin-bottom: 2rem;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  margin: 1rem auto 3rem auto;
`;

const StyledField = styled(Field)`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const RadioGroup = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: var(--color-yellow);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 3rem;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessageStyled = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: -15px;
  margin-bottom: 15px;
`;

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),

  schoolName: Yup.string().required("Required"),
  phoneNumber: Yup.string().matches(
    /^(\+\d{1,3}[- ]?)?\d{10}$/,
    "Phone number is not valid"
  ),
  age: Yup.number().required("Required").positive().integer(),
  talent: Yup.string().required("Required"),

  delegateCategory: Yup.string().required("Required"),
  parentsNames: Yup.string().required("Required"),
});

const TeenDelegateForm = () => {
  return (
    <Container>
      <Heading>Become a Teen Contestant</Heading>
      <Paragraph>
        Please fill out the form below and a representative will get back to you
        with more information.
      </Paragraph>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          homeAddress: "",
          schoolName: "",
          phoneNumber: "",
          age: "",
          talent: "",
          serviceInitiative: "",
          delegateCategory: "",
          parentsNames: "",
          parentsAddress: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          fetch("https://formspree.io/f/xovaqona", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          })
            .then((response) => {
              if (response.ok) {
                resetForm();
                alert("Form submitted successfully!");
              } else {
                alert("Form submission failed!");
              }
              setSubmitting(false);
            })
            .catch((error) => {
              alert("Form submission error: " + error.message);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <StyledField type="text" name="fullName" placeholder="Full Name" />
            <ErrorMessage name="fullName" component={ErrorMessageStyled} />

            <StyledField type="email" name="email" placeholder="Email" />
            <ErrorMessage name="email" component={ErrorMessageStyled} />

            <StyledField
              as="textarea"
              name="homeAddress"
              placeholder="Home Address"
            />
            <ErrorMessage name="homeAddress" component={ErrorMessageStyled} />

            <StyledField
              type="text"
              name="schoolName"
              placeholder="School Name"
            />
            <ErrorMessage name="schoolName" component={ErrorMessageStyled} />

            <StyledField
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
            />
            <ErrorMessage name="phoneNumber" component={ErrorMessageStyled} />

            <StyledField type="number" name="age" placeholder="Age" />
            <ErrorMessage name="age" component={ErrorMessageStyled} />

            <StyledField type="text" name="talent" placeholder="Talent" />
            <ErrorMessage name="talent" component={ErrorMessageStyled} />

            <StyledField
              as="textarea"
              name="serviceInitiative"
              placeholder="Service Initiative"
            />
            <ErrorMessage
              name="serviceInitiative"
              component={ErrorMessageStyled}
            />

            <RadioGroup>
              <label>
                <Field type="radio" name="delegateCategory" value="Teen" />
                Teen
              </label>
              <label>
                <Field type="radio" name="delegateCategory" value="Miss" />
                Miss
              </label>
            </RadioGroup>
            <ErrorMessage
              name="delegateCategory"
              component={ErrorMessageStyled}
            />

            <StyledField
              type="text"
              name="parentsNames"
              placeholder="Parent’s Names"
            />
            <ErrorMessage name="parentsNames" component={ErrorMessageStyled} />

            <StyledField
              as="textarea"
              name="parentsAddress"
              placeholder="Parent's Address"
            />
            <ErrorMessage
              name="parentsAddress"
              component={ErrorMessageStyled}
            />

            <StyledButton type="submit" disabled={isSubmitting}>
              Submit
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

export default TeenDelegateForm;
